import './App.css';
import Home from './pages/Home';
import { ThemeProvider } from '@material-ui/core/styles';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Theme from './utilities/Theme';
import NavBar from './components/NavBar';

function App() {
  return (
    <div className="App" style={{height: '100vh'}}>
      <ThemeProvider theme={Theme}>
        <Router>
          <NavBar />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
