import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';


const styles = (theme) => ({
    linkButton: {
        margin: '10px',
        padding: '10px',
        backgroundColor: theme.colorPalette.primary
    }
});

class Subsidiary extends Component {
  render() {
    var { classes, link, title } = this.props;
    return (
        <Button className={`${classes.linkButton} col-xs-3`} variant="contained" color="primary" onClick={()=>{ window.location.href = link}}>
            {title}
        </Button>
    );
  };
};

export default withStyles(styles)(Subsidiary);