import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { Link } from 'react-router-dom';
import HonerSportsLogo from './HonerSportsLogo';

const styles = (theme) => ({
  appBar: {
    padding: '28px 0px',
    backgroundColor: 'rgb(246, 248, 250)'
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    float:'center',
    margin: '0px 15px',
    color: theme.colorPalette.white,
    padding: '0 0 0 7%'
  },
  links: {
    textDecoration: "none",
    color: theme.colorPalette.white,
    margin: '0px 15px',
    float:'right',
  }
})

class NavBar extends Component {
  render() {
    var { classes } = this.props;

    return (
        <div className={classes.appBar} >
            <HonerSportsLogo />
        </div>
    )
  }
}

export default withStyles(styles)(NavBar);