import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Subsidiaries from '../components/Subsidiaries';
// import PageTitle from '../components/PageTitle';

const styles = (theme) => ({
    wrapper: {
        padding: '0px',
    }
});

class Home extends Component {
  render() {
    var { classes } = this.props;
    return (
        <div className={classes.wrapper} >
            {/* <PageTitle title='Honer Sports'/> */}
            <Subsidiaries />
        </div>
    );
  };
};

export default withStyles(styles)(Home);