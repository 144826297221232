import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Subsidiary from './Subsidiary';

const styles = (theme) => ({
    wrapper: {
        padding: '40px 0px',
        display: 'flex',
        justifyContent: 'center',
        flex: 1
    }
});

class Subsidiaries extends Component {
  render() {
    var { classes } = this.props;
    return (
        <div className={classes.wrapper} >
            <Subsidiary link='http://mybackyardplants.com' title='My Backyard Plants'/>
            <Subsidiary link='http://recipeshare.honersports.com' title='Recipe Share'/>
            <Subsidiary link='http://swgoh.honersports.com' title='SWgoH'/>
        </div>
    );
  };
};

export default withStyles(styles)(Subsidiaries);