import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';

// import jeepImg from '../assets/jeep.jpg';

const styles = (theme) => ({
  titleWrapper: {
    padding: '0px',
  },
  title: {
      fontWeight: 700,
      fontSize: '20px',
      fontFamily: 'Helvetica',
      color: theme.colorPalette.primary
  }
})

class HonerSportsLogo extends Component {
  
  render() {
    var { classes } = this.props;
    return (
        <div className={classes.titleWrapper} >
            {/* <img src={jeepImg} alt='Honer Sports Jeep'/> */}
            <div className={classes.title}>Honer Sports</div>
        </div>
    )
  }
}

export default withStyles(styles)(HonerSportsLogo);